/// @ts-check

// Load our theme assets
// We need to send them straight to Webpack here,
// since they're called in the templates, rather than the JS or SCSS
import './fonts/inter/inter.css';
import './style.scss';
import './images/icon-sprites.svg';

import Router from './utils/Router';
import common from './routes/common';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Specific pages */
});

/** Load Events */
document.addEventListener('DOMContentLoaded', () => routes.loadEvents());
