/// @ts-check

/**
 * @module resource-card
 * @description
 * A custom element that renders a card and handles the functionality
 * of making the whole card clickable. Just provide a child link with
 * `[data-element="resource-card-link"]`.
 */
class ResourceCard extends HTMLElement {
	constructor() {
		super();
	}

	get className() {
		return `card ${this.getAttribute('class-name')}`;
	}

	get as() {
		return this.getAttribute('as') || 'article';
	}

	connectedCallback() {
		this.initialMarkup = this.innerHTML;
		this.render();
	}

	render() {
		this.innerHTML = `
			<${this.as} class="${this.className}" data-element="resource-card-root">
				${this.initialMarkup}
			</${this.as}>
		`;

		this.postRender();
	}

	postRender() {
		this.root = /** @type {HTMLElement} */ (
			this.querySelector('[data-element="resource-card-root"]')
		);
		this.resourceLink = /** @type {HTMLAnchorElement} */ (
			this.querySelector('a[data-element="resource-card-link"]')
		);

		/** @type {number} */
		let down; // Mouse down timestamp
		/** @type {number} */
		let up; // Mouse up timestamp

		// If the card's main resource link is set,
		// we want any clicks on the root card to trigger it
		if (this.resourceLink != null) {
			this.root.style.cursor = 'pointer';

			this.root.onmousedown = () => {
				down = +new Date();
			};

			this.root.onmouseup = () => {
				up = +new Date();

				if (up - down < 200) {
					this.resourceLink.click();
				}
			};
		}
	}
}

export default ResourceCard;
