/// @ts-check
import BurgerMenu from '../components/BurgerMenu';
import Reel from '../components/Reel';
import ResourceCard from '../components/ResourceCard';

export default {
	// JavaScript to be fired on all pages
	init() {
		// initialize custom elements
		if ('customElements' in window) {
			customElements.define('burger-menu', BurgerMenu);
			customElements.define('resource-card', ResourceCard);
			customElements.define('reel-l', Reel);
		}
	},
	// JavaScript to be fired on all pages, after page specific JS is fired
	finalize() {},
};
