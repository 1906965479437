/// @ts-check
import getFocusableElements from '../utils/getFocusableElements';

/**
 * A custom element that renders a burger menu.
 * Provide the nav as a child element.
 * Accepts a `max-width` attribute.
 * @module burger-menu
 * @description
 * A custom element for creating an accessible burger menu.
 *
 * @property {string} maxWidth=9999 The max widht of the burger menu before the regular nav comes back in.
 */
class BurgerMenu extends HTMLElement {
	constructor() {
		super();

		const self = this;

		this.state = new Proxy(
			{
				status: 'open',
				enabled: false,
			},
			{
				set(state, key, value) {
					const oldValue = state[key];
					state[key] = value;

					if (oldValue !== value) {
						self.processStateChange();
					}

					return true;
				},
			}
		);
	}

	get maxWidth() {
		return parseInt(this.getAttribute('max-width') || '9999', 10);
	}

	connectedCallback() {
		this.initialMarkup = this.innerHTML;
		this.render();

		const observer = new ResizeObserver((observedItems) => {
			const { contentRect } = observedItems[0];
			this.state.enabled = contentRect.width <= this.maxWidth;
		});

		// We want to watch the parent like a hawk
		observer.observe(this.parentElement);
	}

	render() {
		this.innerHTML = `
			<div class="burger-menu" data-element="burger-root">
				<button class="burger-menu__trigger" data-element="burger-menu-trigger" type="button" aria-label="Open menu">
					<span class="burger-menu__bar" aria-hidden="true"></span>
				</button>
				<div class="burger-menu__panel" data-element="burger-menu-panel">
					${this.initialMarkup}
				</div>
			</div>
		`;

		this.postRender();
	}

	postRender() {
		this.trigger = this.querySelector('[data-element="burger-menu-trigger"]');
		this.panel = this.querySelector('[data-element="burger-menu-panel"]');
		this.root = this.querySelector('[data-element="burger-root"]');
		this.page = document.querySelector('body');
		this.focusableElements = getFocusableElements(this);

		if (this.trigger && this.panel) {
			this.toggle();

			this.trigger.addEventListener('click', (evt) => {
				evt.preventDefault();

				this.toggle();
			});

			document.addEventListener('focusin', () => {
				if (!this.contains(document.activeElement)) {
					this.toggle('closed');
				}
			});

			return;
		}

		this.innerHTML = this.initialMarkup;
	}

	/**
	 *
	 * In `toggle()`, we can pass an optional forcedStatus parameter which —just
	 * like in the above focus management—let’s us force the component into a
	 * specific, finite state: `open` or `closed`. If that isn’t defined, we set
	 * the current state.status to be open or closed, depending on what the
	 * current status is, using a ternary operator.
	 *
	 * @param {'open' | 'closed'} [forcedStatus]
	 */
	toggle(forcedStatus) {
		if (forcedStatus) {
			if (this.state.status === forcedStatus) {
				return;
			}

			this.state.status = forcedStatus;
		} else {
			this.state.status = this.state.status === 'closed' ? 'open' : 'closed';
		}
	}

	processStateChange() {
		this.root.setAttribute('status', this.state.status);
		this.root.setAttribute('enabled', this.state.enabled ? 'true' : 'false');

		this.manageFocus();

		switch (this.state.status) {
			case 'closed':
				this.trigger.setAttribute('aria-expanded', 'false');
				this.trigger.setAttribute('aria-label', 'Open menu');
				this.page.dataset.allowScroll = 'true';
				break;
			case 'open':
			case 'initial':
				this.trigger.setAttribute('aria-expanded', 'true');
				this.trigger.setAttribute('aria-label', 'Close menu');
				console.log('no scroll');
				this.page.dataset.allowScroll = 'false';
				break;
		}
	}

	manageFocus() {
		if (!this.state.enabled) {
			for (const element of this.focusableElements) {
				element.removeAttribute('tabindex');
			}
			return;
		}

		switch (this.state.status) {
			case 'open':
				for (const element of this.focusableElements) {
					element.removeAttribute('tabindex');
				}
				break;
			case 'closed':
				for (const element of this.focusableElements) {
					if (element.getAttribute('data-element') !== 'burger-menu-trigger') {
						element.setAttribute('tabindex', '-1');
					}
				}
				break;
		}
	}
}

export default BurgerMenu;
